import Swiper, {Navigation, Pagination, Autoplay} from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay]);

export default function sliders() {
    (function($) {
        $(window).on('load', function () {
           // get all sliders, we need to loop them due to different settings + nav
           var swipers = document.querySelectorAll('.testimonials-swiper');
           swipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var controls = closestSection.querySelector('.control');

                // slider settings
                var options = {
                    speed: 600,
                    loop: false,
                    //autoplay: {
                       // delay: 5000,
                        //disableOnInteraction: true,
                    //},
                    centeredSlides: true,
                    autoHeight: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    spaceBetween: 10,
                    centeredSlides: false,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    breakpoints: {
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        992: {
                            slidesPerView: 2.3,
                            centeredSlides: false,
                            spaceBetween: 10,
                        }
                    }
                };

                // For gallery sliders
                if(controls){
                    options.thumbs.swiper = new Swiper(controls, {
                        speed: 600,
                        loop: true,
                        slidesPerView: 2,
                        spaceBetween: 10,
                        freeMode: true,
                        centeredSlides: true,
                        watchSlidesVisibility: true,
                        watchSlidesProgress: true,
                        autoplay: {
                            delay: 5000,
                            disableOnInteraction: true,
                        },
                        breakpoints: {
                            640: {
                                slidesPerView: 3
                            },
                            992: {
                                slidesPerView: 4
                            }
                        }
                    });
                }

                // init slider
                new Swiper(el, options);
            });
       })

        var swiper = new Swiper(".mySwiper", {
          direction: "vertical",
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        autoplay: {
            delay: 2000,
            disableOnInteraction: true,
        },
        loop: true,
    });



    })( jQuery );
}

